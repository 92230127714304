import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "carousel"
}
const _hoisted_2 = {
  key: 1,
  class: "navigation desktop"
}
const _hoisted_3 = {
  key: 2,
  class: "navigation mobile"
}

export function render(_ctx, _cache) {
  const _component_Post = _resolveComponent("Post")
  const _component_Vue3Lottie = _resolveComponent("Vue3Lottie")

  return (_openBlock(), _createElementBlock("div", {
    class: "carousel-wrap",
    onMousedown: _cache[12] || (_cache[12] = $event => (_ctx.startDrag($event))),
    onMouseup: _cache[13] || (_cache[13] = $event => (_ctx.endDrag($event))),
    onTouchstart: _cache[14] || (_cache[14] = $event => (_ctx.startDrag($event))),
    onTouchend: _cache[15] || (_cache[15] = $event => (_ctx.endDrag($event)))
  }, [
    (_ctx.renderedPosts)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.renderedPosts, (post, index) => {
            return (_openBlock(), _createBlock(_component_Post, {
              pos: _ctx.activePos,
              post: post,
              style: _normalizeStyle({'margin-left': (index === 0 ? 'calc('+_ctx.margDft+' + '+_ctx.getMargin()+')' : _ctx.margSize)})
            }, null, 8, ["pos", "post", "style"]))
          }), 256))
        ]))
      : _createCommentVNode("", true),
    (_ctx.renderedPosts)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", {
            class: _normalizeClass(["arrow left", {'disabled': _ctx.activePos == 0}]),
            onClick: _cache[2] || (_cache[2] = $event => (_ctx.moveSlider(-1)))
          }, [
            (_ctx.ArrowJSON)
              ? (_openBlock(), _createBlock(_component_Vue3Lottie, {
                  key: 0,
                  ref: "arrowLeft",
                  animationData: _ctx.ArrowJSON,
                  height: 70,
                  width: 70,
                  loop: true,
                  autoPlay: false,
                  onMouseover: _cache[0] || (_cache[0] = $event => (_ctx.overAnim('arrowLeft'))),
                  onMouseout: _cache[1] || (_cache[1] = $event => (_ctx.outAnim('arrowLeft')))
                }, null, 8, ["animationData"]))
              : _createCommentVNode("", true)
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(["arrow right", {'disabled': _ctx.activePos == _ctx.renderedPosts.length - 1 || (_ctx.renderedPosts.length <= 3 && _ctx.windowSize == 'large') || (_ctx.renderedPosts.length <= 2 && _ctx.windowSize == 'medium') || (_ctx.renderedPosts.length <= 1 && _ctx.windowSize == 'small')}]),
            onClick: _cache[5] || (_cache[5] = $event => (_ctx.moveSlider(1)))
          }, [
            (_ctx.ArrowJSON)
              ? (_openBlock(), _createBlock(_component_Vue3Lottie, {
                  key: 0,
                  ref: "arrowRight",
                  animationData: _ctx.ArrowJSON,
                  height: 70,
                  width: 70,
                  loop: true,
                  autoPlay: false,
                  onMouseover: _cache[3] || (_cache[3] = $event => (_ctx.overAnim('arrowRight'))),
                  onMouseout: _cache[4] || (_cache[4] = $event => (_ctx.outAnim('arrowRight')))
                }, null, 8, ["animationData"]))
              : _createCommentVNode("", true)
          ], 2)
        ]))
      : _createCommentVNode("", true),
    (_ctx.renderedPosts)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", {
            class: _normalizeClass(["arrow left", {'disabled': _ctx.activePos == 0}]),
            onTouchstart: _cache[8] || (_cache[8] = $event => (_ctx.moveSlider(-1)))
          }, [
            (_ctx.ArrowJSON)
              ? (_openBlock(), _createBlock(_component_Vue3Lottie, {
                  key: 0,
                  ref: "arrowLeft",
                  animationData: _ctx.ArrowJSON,
                  height: 70,
                  width: 70,
                  loop: true,
                  autoPlay: false,
                  onMouseover: _cache[6] || (_cache[6] = $event => (_ctx.overAnim('arrowLeft'))),
                  onMouseout: _cache[7] || (_cache[7] = $event => (_ctx.outAnim('arrowLeft')))
                }, null, 8, ["animationData"]))
              : _createCommentVNode("", true)
          ], 34),
          _createElementVNode("div", {
            class: _normalizeClass(["arrow right", {'disabled': _ctx.activePos == _ctx.renderedPosts.length - 1 || (_ctx.renderedPosts.length <= 3 && _ctx.windowSize == 'large') || (_ctx.renderedPosts.length <= 2 && _ctx.windowSize == 'medium') || (_ctx.renderedPosts.length <= 1 && _ctx.windowSize == 'small')}]),
            onTouchstart: _cache[11] || (_cache[11] = $event => (_ctx.moveSlider(1)))
          }, [
            (_ctx.ArrowJSON)
              ? (_openBlock(), _createBlock(_component_Vue3Lottie, {
                  key: 0,
                  ref: "arrowRight",
                  animationData: _ctx.ArrowJSON,
                  height: 70,
                  width: 70,
                  loop: true,
                  autoPlay: false,
                  onMouseover: _cache[9] || (_cache[9] = $event => (_ctx.overAnim('arrowRight'))),
                  onMouseout: _cache[10] || (_cache[10] = $event => (_ctx.outAnim('arrowRight')))
                }, null, 8, ["animationData"]))
              : _createCommentVNode("", true)
          ], 34)
        ]))
      : _createCommentVNode("", true)
  ], 32))
}