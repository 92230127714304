
//import * as Vue from "vue";
import { Vue, Options }  from "vue-class-component";
import Post from "@/components/post/post.vue";
import { Vue3Lottie } from 'vue3-lottie';
import ArrowJSON from '../arrow.json';
@Options({
  components: {
    Post,
    Vue3Lottie
  },
  data() {
    return {
      ArrowJSON,
    }
  },
  props: ['posts', 'featured', 'exclude']
})
export default class Carousel extends Vue {

  posts;
  featured;
  exclude;
  renderedPosts = null;
  firstPost = null;
  activePos = 0;
  windowSize = 'small';
  padSize = '2em';
  margSize = '2em';
  margDft = '2em';

  dragging = false;
  dragX = -1;

  /*created() {
    console.log("created carousel");
    console.log(this.posts);
  }*/

  mounted() {
      window.addEventListener('resize', this.handleResize);
      this.handleResize();

      this.generatePosts();

      //this.renderedPosts = this.posts.slice();
      //console.log(this.windowSize,this.renderedPosts.length);

  }

  generatePosts() {
    let posts = this.posts.slice();
    //console.log(posts);
    
    let itemsProcessed = 0,
      array = [];

    for (let i = 0; i < posts.length; i++) {
      
      if((this.featured && posts[i].ID != this.exclude && posts[i].ID != this.featured.featured_article[0].ID && posts[i].ID != this.featured.featured_news[0].ID) || this.exclude == undefined) {
          array.push(posts[i]);
      }
        
      if (i >= posts.length -1) {
        //this.firstPost = array[0];
        //array.splice(0, 1);
        this.renderedPosts = array;
        //console.log(this.activePos,this.renderedPosts);
        this.$forceUpdate();
      }
    }

    /*posts.forEach((el, index, object) => {
      itemsProcessed++;
      if((this.featured) && (el.ID == this.featured.featured_article[0].ID ||
        el.ID == this.featured.featured_news[0].ID)) {
          object.splice(index, 1);
      } 

      if(itemsProcessed >= object.length) {
        this.renderedPosts = posts;
        this.$forceUpdate();
      }
    })*/
  }

  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  isDotActive(index) {
    if(index == this.activePos) {
        return true;
    } else {
        return false;
    }
  }

  setActivePos(index) {
    this.activePos = index;
  }

  handleResize() {
    //console.log(window.innerWidth);
    this.activePos = 0;
    if(window.innerWidth >= 1100) {
        this.windowSize = 'large';
        this.padSize = '2em';
        this.margSize = '2em';
        this.margDft = '2em';
    } else if(window.innerWidth >= 950) {
        this.windowSize = 'medium';
        this.padSize = '2em';
        this.margSize = '2em';
        this.margDft = '2em';
    } else {
        this.windowSize = 'small';
        this.padSize = '5em';
        this.margSize = '-2em';
        this.margDft = '0em';
    }
  }
  

  moveSlider(dir) {
    //console.log(this.windowSize,this.renderedPosts[this.activePos+dir+1]);
    if(this.renderedPosts[this.activePos+dir]) {
      if( ( this.windowSize == 'large' && this.renderedPosts[this.activePos+dir+2] ) ||
        ( this.windowSize == 'medium' && this.renderedPosts[this.activePos+dir+1] ) ||
        ( this.windowSize == 'small')) {
          this.activePos += dir;
        //console.log('move', this.activePos)
          setTimeout(() => {
            this.$forceUpdate();
          }, 10);
        }
    }
  }

  getMargin() {
    if(this.windowSize == 'large') {
        return (-33.34*this.activePos) + "%";
    } else if(this.windowSize == 'medium') {
        return (-50*this.activePos) + "%";
    } else {
        return (-100*this.activePos) + "%";
    }
  }

  isPosVisible(index) {
    if(index >= this.activePos && ( 
        ( this.windowSize == 'large' && index <= this.activePos+2 ) ||
        ( this.windowSize == 'medium' && index <= this.activePos+1 ) ||
        ( this.windowSize == 'small' && index == this.activePos )
    )) {
        return true;
    } else {
        return false;
    }
  }

  isNextArrowActive() {
      if( ( this.windowSize == 'large' && this.renderedPosts[this.activePos+3] ) ||
        ( this.windowSize == 'medium' && this.renderedPosts[this.activePos+2] ) ||
        ( this.windowSize == 'small') && this.renderedPosts[this.activePos+1]) {
          return true; 
        } else {
          return false;
        }
  }

  goToBlog(post) {
    this.$store.commit('setActivePost', post);
    this.$router.push({
      name: 'blogpost',
      params: { id: decodeURIComponent(post.post_name) }
    });
  }

  goToTag(tag) {
    this.$router.push({
      name: 'tag',
      params: { name: tag }
    });
  }

  startDrag(event) {
    this.dragging = true;

    if(event.type == 'touchstart') {
      this.dragX = event.touches[0].clientX;
    } else {
      this.dragX = event.x;

    }
  }

  endDrag(event) {
    this.dragging = false;

    let x = (event.type == 'touchend' ? event.changedTouches[0].clientX : event.x);
    
    if(Math.abs(x - this.dragX) > 7) {
      event.preventDefault();

      if(x < this.dragX && this.isNextArrowActive()) {
        this.setActivePos(this.activePos+1);
      } else if(x > this.dragX && this.activePos !== 0){
        this.setActivePos(this.activePos-1);

      }
    }
  }

  overAnim(id) {
    if(id == 'arrowLeft')
      (this as any).$refs.arrowLeft.play();
    else if(id == 'arrowRight')
      (this as any).$refs.arrowRight.play();
  }

  outAnim(id) {
    if(id == 'arrowLeft')
      (this as any).$refs.arrowLeft.stop();
    else if(id == 'arrowRight')
      (this as any).$refs.arrowRight.stop();
  }
}
