
import { Options, Vue } from 'vue-class-component';

import { event } from 'vue-gtag';

@Options({
  components: {
    
  },
  computed: {
    CMS_URL () {
      return this.$store.getters.getCMSURL;
    },
    activePost () {
      return this.$store.getters.getActivePost;
    }
  }
})
export default class AwardsPost extends Vue {

  CMS_URL;
  activePost;
  created() {
    //console.log(this.$route.params.id);
    this.getPost();
  }

  mounted() {
    let cookies = (this.$parent as any).$root.cookies,
      gdprCookie = cookies.get("gdprCookie"),
      id = this.$route.params.id;

    if(gdprCookie == 'true') {

      event(null, { 
        event: null, // Event type [default = 'interaction'] (Optional)
        category: 'Showcase Post',
        action: 'click',
        label: 'Showcase Post View: '+id,
        value: 5000,
        noninteraction: false, // Optional
      });

    }
  }

  getPost() {
    let id = this.$route.params.id;
    //console.log(id);
    this.axios.get(this.CMS_URL + 'mt7rk/get_post_by_id&id='+id).then((response) => {
      //console.log(response.data);
      this.$store.commit('setActivePost', JSON.parse(response.data));
      //console.log(this.activePost);
    });
  }


}
