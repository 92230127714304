
//import * as Vue from "vue";
import { slotFlagsText } from "@vue/shared";
import { Vue, Options }  from "vue-class-component";
import { Vue3Lottie } from 'vue3-lottie';
import LogoJSON from '../mt7rk.json';
import ArrowJSON from '../arrow.json';
import LogoBlueJSON from '../blue.json';

@Options({
  components: {
    Vue3Lottie
  },
  data() {
    return {
      LogoJSON,
      ArrowJSON,
      LogoBlueJSON
    }
  },
  computed: {
    CMS_URL () {
      return this.$store.getters.getCMSURL;
    },
    /*tags () {
      return this.$store.getters.getTags;
    },*/
    events () {
      return this.$store.getters.getEvents;
    },
    directories() {
      return this.$store.getters.getDirectories;
    }
  },
  watch: {
    '$route' (to) {
      //console.log(to);

      let pagename = to.name + (to.params.slug ? '-'+to.params.slug : '');
      this.$store.commit('setPage', pagename);
      this.search = false;
      window.scrollTo(0, 0); // values are x,y-offset

      // react to route changes...
    }
  }
})
export default class NavBar extends Vue {

  //private navOpen = false;
  navOpen = false;
  calOpen = false;
  scrolled = false;
  search = false;
  searchOpen = false;
  tags;
  events;
  directories;
  eventTags;
  directoryTags;
  directoryCountries;
  CMS_URL;
  searchTag = 'all';
  searchCountry = 'all';
  searchType = 'all';
  searchContent = '';
  searchDirectory = '';
  today = new Date();
  monthFilter = '';
  yearFilter = '';
  LogoJSON;
  LogoBlueJSON;
  totalSearch = 0;
  numSearch = 0;
  currentFilter = null;
  moreTags = false;
  currentType = 'All';
  currentCountry = 'All';
  currentTag = 'All';
  selectedTags = [];
  
  get page() {
    return this.$store.state.page;
  }

  created() {
    this.tags = [];
    this.eventTags = [];
    this.directoryTags = [];
    this.directoryCountries = [];

    this.getEvents();
    this.getDirectories();
    this.getTags();
  }

  mounted() {
    // define posts by years
    //console.log('refs',this.$refs.logoL);
    window.scrollTo(0, 0); // values are x,y-offset
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.handleResize);
    //this.handleScroll();

    setTimeout(() => {
      document.getElementById("logoBtn").classList.remove("active");
    }, 5000);
  }

  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.handleResize);
  }

  showMoreTags(e) {
    this.moreTags = true;
    e.preventDefault();
  }

  getTags() {
    //console.log("getting tags");
    //if(!this.tags) {
      this.axios.get(this.CMS_URL + 'mt7rk/get_most_used_tags').then((response) => {
        //console.log("geot tags");

        //console.log(response.data);
        this.tags = response.data;
        //this.$store.commit('setTags', response.data);
        //console.log(this.tags);
      });
      /*for(let i = 0; i < this.events.length; i++) {
        //console.log(this.events[i]);
        if(this.events[i].category && this.events[i].category.length > 0) {
          for(let j = 0; j < this.events[i].category.length; j++) {

            if(!tags.some(e => e.term_id === this.events[i].category[j].term_id)) {
              console.log("already exists");
              tags.push(this.events[i].category[j]);

            }

            if(i == this.events.length-1 &&
              j == this.events[i].category.length-1) {
                this.eventTags = tags;

                console.log(this.eventTags);

              }

          }
          //console.log(this.events[i].category);
        } else if(i == this.events.length-1) {
            this.eventTags = tags;
        }
      }*/
    //}
  }

  getEventTags() {
    let tags = [];
      for(let i = 0; i < this.events.length; i++) {
        //console.log(this.events[i]);
        if(this.events[i].category && this.events[i].category.length > 0) {
          for(let j = 0; j < this.events[i].category.length; j++) {

            if(!tags.some(e => e.term_id === this.events[i].category[j].term_id)) {
              //console.log("already exists");
              tags.push(this.events[i].category[j]);

            }

            if(i == this.events.length-1 &&
              j == this.events[i].category.length-1) {
                this.eventTags = tags;

                //console.log(this.eventTags);

              }

          }
          //console.log(this.events[i].category);
        } else if(i == this.events.length-1) {
            this.eventTags = tags;
        }
      }
  }

  getDirectoriesTags(array) {
    let tags = [];

    for (let i = 0; i < array.length; i++) {
      // get directories tags
      if (array[i].tags && array[i].tags.length > 0) {
        for (let j = 0; j < array[i].tags.length; j++) {

          if (!tags.some(e => e.term_id === array[i].tags[j].term_id)) {
            //console.log('******',array[i].tags[j]);
            tags.push(array[i].tags[j]);
          }

          if (i == array.length - 1 &&
            j == array[i].tags.length - 1) {
            tags.sort((a, b) => (a.slug > b.slug) ? 1 : -1);
            this.directoryTags = tags;
          }

        }
      }

      // set tags
      if (i == array.length - 1) {
        tags.sort((a, b) => (a.slug > b.slug) ? 1 : -1);
        this.directoryTags = tags;
      }
    }
  }

  getCountries(array) {
    let countries = [],
      uniqueCountries = [];

    for (let i = 0; i < array.length; i++) {

      if (array[i].custom_fields.country) {
        countries.push(array[i].custom_fields.country.toLowerCase());
      } 
      // set tags and country at the end
      if (i == array.length - 1) {
        // remove duplicate country
        uniqueCountries = countries.reduce( 
          function(a,b) {
            if (a.indexOf(b) < 0 ) a.push(b);
              return a;
        },[]);
        //console.log('unique country',uniqueCountries);
        this.directoryCountries = uniqueCountries.sort();
      }
    }
  }

  setTag(slug) {
    this.searchTag = slug;
  }

  setCountry(slug) {
    this.searchCountry = slug;
  }

  showTagDirectory(tag,name) {
    this.searchType = 'all';
    this.currentType = 'All';
    this.searchCountry = 'all';
    this.currentCountry = 'All';
    this.setFilter(tag,'tag','add',name);
    this.$router.push({
      name: 'directory'
    });
  }

  setFilterCountry(slug,type,name=null) {
    let action = (this.searchCountry == slug ? 'del' : 'add');
    this.setFilter(slug,type,action,name);
  }

  setFilterTag(slug,type,name=null) {
    let action = (this.searchTag == slug ? 'del' : 'add');
    this.setFilter(slug,type,action,name);
  }

  tagSelection(tag,cond) {
    let action = (cond ? 'del' : 'add');
    //console.log(action, tag);
    this.setFilter(tag.slug,'tag',action,tag.name);
  }

  setFilter(slug,type,action,name=null) {
    this.numSearch = 0;
    //console.log('slug:',slug,'type:',type,name,action);
    let array = [],
      arrayCountry = [],
      arrayTags = [],
      detect = null,
      country,
      postType,
      condType = false,
      condCountry = false,
      condTags = false,
      exist = false;

    let div = 'filterCountry',
      div2 = 'filterCountryMob',
      div3 = 'filterTags',
      div4 = 'filterTagsMob';

    // close all filter
    if(div && slug == 'all')
      this.closeFilter(div);
    if(div2 && slug == 'all')
      this.closeFilter(div2);
    if(div3 && slug == 'all')
      this.closeFilter(div3);
    if(div4 && slug == 'all')
      this.closeFilter(div4);

    //console.log(action,'this.searchType',this.searchType);
    if(type == 'type') {
      if((this.searchType == 'company' && slug == 'individual') || (this.searchType == 'individual' && slug == 'company')) {
        if(action == 'del' && slug == 'individual') {
          this.currentType = 'Freelancer';
          this.searchType = 'individual';
        }
        else if(action == 'del' && slug == 'company') {
          this.currentType = 'Companies';
          this.searchType = 'company';
        }
        else {
          this.currentType = 'All';
          this.searchType = 'all';
        }
      } else {
        this.currentType = (name ? name : 'All');
        this.searchType = slug;
      }
      this.searchCountry = 'all';
      this.searchTag = 'all';
      this.currentCountry = 'All';
      this.currentTag = 'All';
    }
    else if(type == 'country') {
      this.currentCountry = (name == null || action == 'del' ? 'All' : name);
      this.searchCountry = (name == null || action == 'del' ? 'all' : slug);
      if(action == 'del')
        slug = 'all';
    }
    else {
      this.currentTag = (name == null || action == 'del' ? 'All' : name);
      this.searchTag = slug;
      if(action == 'del') {
        for(let i = 0; i < this.selectedTags.length;i++) {
          if(this.selectedTags[i] == slug) {
            this.selectedTags.splice(i,1);
          }
        }
        //slug = 'all';
      } else {
        this.selectedTags.push(this.searchTag);
      }

      //console.log(this.selectedTags);
    }

    if(type == 'type' && slug == 'all') {
      this.searchCountry = 'all';
      this.searchTag = 'all';
      this.currentCountry = 'All';
      this.currentTag = 'All';
      this.selectedTags = [];
    }

    //console.log('this.currentCountry',this.currentCountry);
    //console.log(type,'this.currentTag',this.currentTag);
    
    //console.log('this.searchType:',this.searchType);
    //console.log('this.searchCountry:',this.searchCountry);
    //console.log('this.searchTag:',this.searchTag);
    for (let i = 0; i < this.directories.length; i++) {
      //console.log('-----start loop-------');
      //console.log(this.directories[i].custom_fields.country);
      country = (this.directories[i].custom_fields.country ? this.directories[i].custom_fields.country.toLowerCase() : null);
      postType = this.directories[i].post_type;
      // filter by type
      if(type == 'type') {
        //console.log('filter by type');
        detect = postType;
        condType = (detect == slug && this.searchCountry == 'all') 
          || (detect == slug && country == this.searchCountry) 
          || (slug == 'all' && ((this.searchCountry !='all' && country == this.searchCountry) || this.searchCountry == 'all'))
          || (slug == 'all' && country == this.searchCountry);
        
      }// filter by country
      else if(type == 'country') {// && this.directories[i].custom_fields.country) {
        //console.log('filter by country',slug);
        //console.log((detect == slug && this.searchType == 'all'));
        //console.log((detect == slug && postType == this.searchType));
        //console.log((slug == 'all' && (postType == this.searchType || this.searchType == 'all')));
        detect = country;
        condCountry = (detect == slug && this.searchType == 'all') 
          || (detect == slug && postType == this.searchType) 
          || (slug == 'all' && (postType == this.searchType || this.searchType == 'all'));
        //console.log('postType:',postType,'this.searchType:',this.searchType,postType == this.searchType);
        //console.log(this.searchType == 'all');
        
      }// filter by tag
      else if(type == 'tag') {
        //console.log('filter by tag');
        exist = false;
        detect = this.directories[i].tags;
        condTags = (this.searchType == 'all') 
          || (postType == this.searchType) 
          || (slug == 'all' && postType == this.searchType);
        
        if(slug != 'all') {
          for(let t = 0; t < detect.length; t++) {
            //console.log('tag:',detect[t].slug,this.searchType);
            //console.log(detect[t].slug == slug,this.searchType != 'all',postType == this.searchType);
            //console.log('slug:',slug);
            if(this.selectedTags.indexOf(detect[t].slug) > -1)
              //console.log('detect slug:',detect[t].slug);

            if(detect[t].slug == slug && action == 'add' && ((this.searchType != 'all' &&  postType == this.searchType) || this.searchType == 'all') || this.selectedTags.indexOf(detect[t].slug) > -1) {
              exist = true;
              //console.log('exist');
            }

            if(t == detect.length - 1 && exist && condTags) {
              // add post
              array.push(this.directories[i]);
              this.numSearch++;
            }
          }
        } else {
          //console.log('slug all!!!');
          if(condTags) {
            array.push(this.directories[i]);
            this.numSearch++;
          }
        }
      }
      // add post
      //console.log('condType',condType);
      //console.log('condCountry',condCountry);
      if(condType || condCountry) {
        //console.log('add post',this.directories[i]);
        //console.log(this.currentFilter);
        array.push(this.directories[i]);
        this.numSearch++;
        // force clear condition for country and type
        condCountry = false;
        condType = false;
      }

      // get all the country
      if((type == 'country' && this.searchType == 'company') || (type == 'type' && slug == 'all')) {
        arrayCountry.push(this.directories[i]);
      }  
      // get all the tags
      if((type == 'tag' && this.searchType == 'company') || (type == 'type' && slug == 'all')) {
        arrayTags.push(this.directories[i]);
      }

      // at the end set tags ans country for the current filter 
      if (i == this.directories.length - 1) {
        this.$store.commit('setDirectory', array);
        if(type == 'tag' || (type == 'type' && slug == 'all')) {
          this.getDirectoriesTags(arrayTags);
        } else {
          this.getDirectoriesTags(array);
        }
        if(type == 'country' || (type == 'type' && slug == 'all')) {
          // set country with all of them
          this.getCountries(arrayCountry);
        } else {
          // set the country with current filter
          this.getCountries(array);
        }
      }
    }
  }

  hasTag(event) {
    for(let i = 0; i < event.category.length; i++) {
      if(event.category[i].slug == this.searchTag) {
        return true;
      } else if(i == event.category.length-1) {
        return false;
      }
    }
  }

  getEvents() {
    if(!this.events) {
      this.axios.get(this.CMS_URL + 'mt7rk/events').then((response) => {
        //console.log(response.data);
        this.$store.commit('setEvents', JSON.parse(response.data).posts);
        //console.log(this.events);
            this.getEventTags();

      });
    } else {
          this.getEventTags();

    }
  }

  getDirectories() {
    if (!this.directories) {
      this.axios.get(this.CMS_URL + 'mt7rk/directory_draft').then((response) => {
        //console.log(response.data);
        this.$store.commit('setDirectories', JSON.parse(response.data).posts);
        //console.log(this.events);
        this.totalSearch = this.directories.length;
        this.numSearch = this.directories.length;
        this.getDirectoriesTags(this.directories);
        this.getCountries(this.directories);

      });
    } else {
      this.totalSearch = this.directories.length;
      this.numSearch = this.directories.length;
      this.getDirectoriesTags(this.directories);
      this.getCountries(this.directories);
    }
  }

  setSearchTag(tag) {
    this.searchTag = tag;
  }

  handleSearch(section=null) {
    let query,
      path;
    if(section == 'directory') {
      query = this.searchDirectory;
      path = 'directorysearch';
    }
    else {
      query = this.searchContent;
      path = 'search';
    }
    //path = 'search';
    //console.log("in");
    if(query) {
      this.navOpen = false;
      this.calOpen = false;
      this.searchOpen = false;
      //console.log(query);
      setTimeout(() => {
        this.$router.push({
          name: path,
          params: { 
            query: encodeURIComponent(query) 
          }
        });
      }, 10);
      
    }
  }

  monthChange(this) {
    //console.log(this.monthFilter);
  }

  yearChange(this) {
    //console.log(this.yearFilter);
  }

  closeAllFilter() {
    if(this.search) {
      let div = 'filterCountry',
        div2 = 'filterCountryMob',
        div3 = 'filterTags',
        div4 = 'filterTagsMob';

      // close all filter
      this.closeFilter(div);
      this.closeFilter(div2);
      this.closeFilter(div3);
      this.closeFilter(div4);
      this.toggleSearch();
    }
  }

  handleResize() {
    this.closeAllFilter();
  }

  handleScroll() {
    if(window.scrollY > 0) {
      this.scrolled = true;
      (this as any).$refs.logoL.stop();
      (this as any).$refs.logoLMob.stop();
      this.closeAllFilter();
    } else {
      this.scrolled = false;
      (this as any).$refs.logoL.play();
      (this as any).$refs.logoLMob.play();
    }

    //this.scrollPos = window.scrollY;
  }

  toggleNav() {
    this.navOpen = !this.navOpen;

    if(!this.navOpen) {
      this.calOpen = false;
    }

    this.searchOpen = false;
  }

  toggleSearch() {
    this.search = !this.search;
  }

  enableSearch() {
    this.search = true;
  }

  toggleCalendar() {
    this.calOpen = !this.calOpen;

    if(this.calOpen) {
      this.navOpen = true;
    }
  }

  toggleSearchMenu() {
    this.searchOpen = !this.searchOpen;
  }

  toggleFilter(filter) {
    if(!this.search && window.innerWidth >= 950) {
      this.toggleSearch();
    } else if(!this.searchOpen && window.innerWidth < 950) {
      this.navOpen = !this.navOpen;
      this.toggleSearchMenu();
    }

    if(window.innerWidth >= 950) {
      setTimeout(() => {
        this.openFilter(filter);
      }, 100);
    } else {
      setTimeout(() => {
        this.openFilter(filter+'Mob');
      }, 200);
    }
  }

  openSearch() {
    if(window.innerWidth >= 950)
      this.toggleSearch()
    else {
      this.navOpen = !this.navOpen;
      this.toggleSearchMenu();
    }
  }

  goHome() {
    /*if(this.page == 'directory') {
      this.goTo('directory');
    } else if(this.page == 'awards') {
      this.goTo('awards');
    } else {*/
      this.goTo('blog');
    //}
    //console.log(this.page);
  }

  goTo(name,slug=null,e=null) {
    this.navOpen = false;
    this.searchContent = '';
    //console.log(name,slug);
    //console.log(this.page);
    if(this.page != name && name !== 'search' && name !== 'tag') {
      //console.log("pushing");
      this.calOpen = false;
      this.searchOpen = false;
      if(slug) {
        this.$router.push({
          name: name,
          params: { id: slug }
        });
      }
      else
        this.$router.push({name: name});
    } else if(this.page == 'pageView') {
      this.$router.push({
          name: name,
          params: { id: slug }
        });

    } else {
      window.scrollTo(0, 0); // values are x,y-offset
    }
    if(e)
      e.preventDefault();  
  }

  goToTag(tag) {
    //console.log('goto',tag);
    setTimeout(() => {
      this.calOpen = false;
      this.searchOpen = false;
      this.navOpen = false;
      this.$router.push({
        name: 'tag',
        params: { name: decodeURIComponent(tag) }
      });
    }, 10);
  }

  getMonthName(month) {
    let months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
    return months[parseInt(month)-1];
  }

  overAnim(id) {
    if(id == 'arrowL')
      (this as any).$refs.arrowL.play();
    else if(id == 'arrowLMob')
      (this as any).$refs.arrowLMob.play();
    else if(id == 'logoL')
      (this as any).$refs.logoL.play();
    else if(id == 'logoLMob')
      (this as any).$refs.logoLMob.play();
  }

  outAnim(id) {
    if(id == 'arrowL')
      (this as any).$refs.arrowL.stop();
    else if(id == 'arrowLMob')
      (this as any).$refs.arrowLMob.stop();
    else if(id == 'logoL')
      (this as any).$refs.logoL.stop();
    else if(id == 'logoLMob')
      (this as any).$refs.logoLMob.stop();
  }

  openFilter(filter) {
    let div = document.getElementById(filter),
      main = div.parentElement;

    // open current filter
    main.style.height = (div.offsetHeight+50)+'px';
    // close all filter
    if(this.currentFilter || this.currentFilter == filter) {
      this.closeFilter(this.currentFilter,filter);
    } else {
      if(this.currentFilter == filter)
        this.currentFilter = null;
      else
        this.currentFilter = filter;
    }
  }

  closeFilter(div,filter=null) {
    let elem = document.getElementById(div);

    if(elem)
      elem.parentElement.style.height = '42px';
    
    if(filter !== null) {
      if(this.currentFilter == filter)
        this.currentFilter = null;
      else
        this.currentFilter = filter;
    } else {
      this.currentFilter = null;
    }
  }
}
