import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "grid-wrap" }

export function render(_ctx, _cache) {
  const _component_Post = _resolveComponent("Post")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.posts)
      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.posts, (post, index) => {
          return (_openBlock(), _createBlock(_component_Post, {
            post: post,
            section: _ctx.section
          }, null, 8, ["post", "section"]))
        }), 256))
      : _createCommentVNode("", true)
  ]))
}