
//import * as Vue from "vue";
import { Vue, Options }  from "vue-class-component";

import { bootstrap } from 'vue-gtag';

@Options({
  components: {},
  computed: {
    CMS_URL () {
      return this.$store.getters.getCMSURL;
    }
  }
})
export default class Gdpr extends Vue {

  close = false;

  closeBox(value,e) {
    this.$store.commit('setGdprCookie', value);
    if(value === true) {
      bootstrap();
    }
    this.close = true;
    e.preventDefault();
  }

}
