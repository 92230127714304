import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "bottom" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "info" }
const _hoisted_5 = {
  key: 0,
  class: "author-date"
}

export function render(_ctx, _cache) {
  return (_ctx.post)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(['post-wrap '+_ctx.post.post_type, {'arabic': _ctx.post.custom_fields.language == 'arabic', 'visible': _ctx.isVisible()}]),
        ref: "el"
      }, [
        (_ctx.post.custom_fields.image && _ctx.post.custom_fields.image.sizes)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "img-wrap box-shadow",
              onClick: _cache[0] || (_cache[0] = $event => (_ctx.goToBlog(_ctx.post,_ctx.section))),
              style: _normalizeStyle({backgroundImage: 'url('+_ctx.post.custom_fields.image.sizes.large+')'})
            }, null, 4))
          : (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: "img-wrap box-shadow",
              onClick: _cache[1] || (_cache[1] = $event => (_ctx.goToBlog(_ctx.post,_ctx.section)))
            })),
        (_ctx.post.custom_fields.logo)
          ? (_openBlock(), _createElementBlock("div", {
              key: 2,
              class: "logo box-shadow right reversed nohover flex align-center",
              onClick: _cache[2] || (_cache[2] = $event => (_ctx.goToBlog(_ctx.post,_ctx.section)))
            }, [
              _createElementVNode("img", {
                src: _ctx.post.custom_fields.logo.sizes.large
              }, null, 8, _hoisted_1)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", {
            class: "title-wrap",
            onClick: _cache[3] || (_cache[3] = $event => (_ctx.goToBlog(_ctx.post,_ctx.section)))
          }, [
            _createElementVNode("h5", null, _toDisplayString(_ctx.post.post_title), 1),
            (_ctx.post.custom_fields.intro_text)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: "intro",
                  innerHTML: _ctx.post.custom_fields.intro_text
                }, null, 8, _hoisted_3))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_4, [
            (_ctx.post.custom_fields.date)
              ? (_openBlock(), _createElementBlock("p", _hoisted_5, [
                  _createTextVNode(_toDisplayString((_ctx.post.custom_fields.author ? _ctx.post.custom_fields.author.post_title : '')) + " - ", 1),
                  _createElementVNode("span", null, _toDisplayString(_ctx.post.custom_fields.date), 1)
                ]))
              : _createCommentVNode("", true),
            (_ctx.post.post_type == 'individual' || _ctx.post.post_type == 'company')
              ? (_openBlock(), _createElementBlock("a", {
                  key: 1,
                  class: "category",
                  onClick: _cache[4] || (_cache[4] = $event => (_ctx.goToBlog(_ctx.post,_ctx.section)))
                }, _toDisplayString(_ctx.post.custom_fields.country), 1))
              : (_ctx.post.category && _ctx.post.category.length > 0)
                ? (_openBlock(), _createElementBlock("a", {
                    key: 2,
                    class: "category",
                    onClick: _cache[5] || (_cache[5] = $event => (_ctx.goToCat(decodeURIComponent(_ctx.post.category[0].slug),$event)))
                  }, _toDisplayString(_ctx.post.category[0].name), 1))
                : (_ctx.post.tags && _ctx.post.tags.length > 0)
                  ? (_openBlock(), _createElementBlock("a", {
                      key: 3,
                      class: "category",
                      onClick: _cache[6] || (_cache[6] = $event => (_ctx.goToTag(decodeURIComponent(_ctx.post.tags[0].slug),$event)))
                    }, _toDisplayString(_ctx.post.tags[0].name), 1))
                  : _createCommentVNode("", true)
          ])
        ])
      ], 2))
    : _createCommentVNode("", true)
}