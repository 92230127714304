<style lang="scss" src="./index.scss"></style>
<template src="./index.html"></template>

<script>
//import Vue from "vue";
import NavBar from "./components/nav-bar/nav-bar.vue";
import Footer from './components/footer/footer.vue';
import Gdpr from "./components/gdpr/gdpr.vue";
import { osName, deviceType, browserName } from "mobile-device-detect";
import { useCookies, globalCookiesConfig } from "vue3-cookies";

import { bootstrap } from 'vue-gtag';

globalCookiesConfig({
  expireTimes: "365d",
  path: "/",
  domain: "mt7rk.me"
});

export default {
  name: "App",
  components: {
    NavBar,
    Footer,
    Gdpr
  },
  created() {
    //initialization
    
  }, //end created
  mounted() {
    window.addEventListener("resize", this.resize);
    //initial functionality
    this.resize();
    this.getDeviceClass();
    this.osClass = osName.replace(/ /g, "_");
    this.scriptClass = "script-only";
    this.isJS = true;
    let gdprCookie = this.cookies.get("gdprCookie");
    //console.log('gdprCookie',gdprCookie);
    if(gdprCookie) {
      this.cookieAuth = false;
      if(gdprCookie == 'true')
        bootstrap();
      //this.$gtm.enable();
    }

  },
  beforeUnmount() {
    //on destroy
    //event handlers
    window.removeEventListener("resize", this.resize);
  }, //end beforeDestroy
  data() {
    //needed for view, no complex computation needed
    return {
      osClass: "",
      screenClass: "big", //default to mobile just in case
      deviceClass: "desktop",
      scriptClass: "noscript",
      title: "mt7rk | Everything Arab and animated",
      description: "test description",
      browserName: browserName,
      isJS: false,
      cookieAuth: true
    };
  }, //end data
  computed: {
    //functions w/ return obj
    page() {
      return this.$store.state.page;
    },
    contactOpen() {
      return this.$store.state.contactOpen;
    },
    gdprCookie() {
      return this.$store.state.gdprCookie;
    }
  }, //end computed
  methods: {
    //no return obj

    //window resize event handler
    resize() {
      this.getScreenClass();
    }, //end resize

    //determines screen size class
    getScreenClass() {
      //const deviceClass = this.getDeviceClass;

      if (this.deviceClass == "desktop") {
        //if desktop
        if (window.innerWidth < 750) {
          this.screenClass = "small";
        } else {
          this.screenClass = "big";
        }
      } else if (window.innerHeight > window.innerWidth) {
        //mobile/tablet
        this.screenClass = "portrait";
      } else {
        this.screenClass = "landscape";
      }
    }, //end getScreenClass

    //detects mobile/tablet/desktop
    getDeviceClass() {
      let deviceClass = deviceType ? deviceType : "desktop";

      if (
        this.deviceClass == "desktop" &&
        this.osClass == "Mac_OS" &&
        navigator.maxTouchPoints > 1
      ) {
        deviceClass = "tablet";
      }

      this.deviceClass = deviceClass;
    }, //end getDeviceClass
   // getImage(name) {
    //  return require(`~/assets/img/${name}`);
    //},
    // parse, replace image/shared url path and return the meta info
  }, //end methods
  watch: {
    osClass(newValue) {
      this.$store.commit("setOSClass", newValue);
    },
    screenClass(newValue) {
      this.$store.commit("setScreenClass", newValue);
    },
    deviceClass(newValue) {
      this.$store.commit("setDeviceClass", newValue);
    },
    gdprCookie(newValue) {
      this.cookies.set("gdprCookie", newValue);
      this.$store.commit("setGdprCookie", newValue);
    }
  },
  setup() {
    const { cookies } = useCookies();
    return { cookies };
  }
}; //end app
</script>

