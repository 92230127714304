
//import * as Vue from "vue";
import { Vue, Options }  from "vue-class-component";
@Options({
  components: {},
  /*watch: {
    '$route' (to, from) {
      //console.log(to);

      //let pagename = to.name + (to.params.slug ? '-'+to.params.slug : '');
      //this.$store.commit('setPage', pagename);
      //this.search = false;
      //window.scrollTo(0, 0); // values are x,y-offset

      // react to route changes...
    }
  }*/
})
export default class Footer extends Vue {


  /*created() {
    console.log("created footer");
    console.log(this.page);
  }*/

  get page() {
    return this.$store.state.page;
  }

  mounted() {
    //console.log("mounted",this.$props);
  }

  beforeUnmount() {
    //window.removeEventListener('scroll', this.handleScroll);
  }

  goTo(name,slug=null) {
    //console.log(name,slug);
    //console.log(this.page);
    if(this.page != name && name !== 'search' && name !== 'tag') {
      //console.log("pushing");
      if(slug) {
        this.$router.push({
          name: name,
          params: { id: slug }
        });
      }
      else
        this.$router.push({name: name});
    } else if(this.page == 'pageView') {
      this.$router.push({
          name: name,
          params: { id: slug }
        });
    } else {
      window.scrollTo(0, 0); // values are x,y-offset
    }
      
  }
}
