import { createApp } from 'vue'
import { createGtm } from '@gtm-support/vue-gtm'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueGtag from "vue-gtag";
import Vue3Lottie from 'vue3-lottie'
import 'vue3-lottie/dist/style.css'
//import Vue3TouchEvents from "vue3-touch-events";

const app = createApp(App)
app.use(store)
app.use(router)
app.use(VueAxios, axios)
app.use(VueGtag, {
  bootstrap: false,
  config: { 
    id: 'G-LCFGQNTVHJ',
    params: {
      anonymize_ip: true
    }
  }
}, router)
app.use(Vue3Lottie);
//app.use(Vue3TouchEvents);
app.mount('#app')
