
//import * as Vue from "vue";
import { Vue, Options }  from "vue-class-component";
@Options({
  components: {},
  props: ['src', 'color', 'related','type','caption','lang', 'colorName', 'totalSlide', 'index']
})
export default class Lightbox extends Vue {

  totalSlide;
  index;
  src;
  type;
  /*created() {
    console.log("created lightbox");
  }

  mounted() {
    console.log(this.type,this.src);
    console.log(this.index, this.totalSlide);
  }*/

  beforeUnmount() {
    //window.removeEventListener('scroll', this.handleScroll);
  }

  closeLightbox(e) {
    //console.log(this.$parent);
    (this.$parent as any).closeLightbox();
    if(e)
      e.preventDefault();
  }

  goToBlog(post,e) {
    //console.log(this.$parent);
    (this.$parent as any).getPostByID(post.ID);
    
    this.$router.replace({
      name: 'blogpost',
      params: { id: post.post_name }
    });

    setTimeout(() => {
      this.closeLightbox(e);
    }, 100);

    if(e)
      e.preventDefault();
  }

  changeSlide(index) {
    if(index === 1)
      (this as any).$parent.currentIndex++;
    else
      (this as any).$parent.currentIndex--;
  }
}
