
import { Options, Vue } from 'vue-class-component';

import { event } from 'vue-gtag';

@Options({
  components: {
    
  },
  computed: {
    CMS_URL () {
      return this.$store.getters.getCMSURL;
    }
  },
  watch: {
    '$route' (to) {
      //console.log("IN WATCHER");
      //console.log(to);
      //console.log(from.ID);
      if(to.name == 'pageView') {
        this.warning = '';
        this.success = '';
        this.getPostByID(to.params.id);
      }

      // react to route changes...
    }
  }
})
export default class PageView extends Vue {

  CMS_URL;
  listeners = [];
  activePost = null;
  relatedPost = null;
  parsed = false;
  scrolled = false;
  email = '';
  name = null;
  message = null;
  warning = '';
  success = '';
  sub = null;
  public lightboxSrc = null;
  public authorPosts = null;
  public authorData = null;
  created() {
    //console.log(this.$route.params.id);
    this.getPost();
  }

  mounted() {
    let cookies = (this.$parent as any).$root.cookies,
      gdprCookie = cookies.get("gdprCookie"),
      id = this.$route.params.id;

    if(gdprCookie == 'true') {

      event(null, { 
        event: null, // Event type [default = 'interaction'] (Optional)
        category: 'Page',
        action: 'click',
        label: 'Page View: '+id,
        value: 5000,
        noninteraction: false, // Optional
      });

    }

    if(this.activePost && !this.parsed) {
      this.parseDoc();
    }

    let twitterScript = document.createElement('script');
        twitterScript.setAttribute('src', 'https://platform.twitter.com/widgets.js' );
        document.head.appendChild(twitterScript);

        twitterScript.onload = () => {
          (window as any).twttr.widgets.load();
        }

    //window.addEventListener('scroll', this.handleScroll);

  }

  beforeUnmount() {
    //window.removeEventListener('scroll', this.handleScroll);
  }

  getPost() {
    let id = this.$route.params.id;
    //if(!this.activePost) {
      //console.log('get post--',id);
      this.getPostByID(id);
    /*} else {
      console.log(this.activePost);
      this.getRelatedPost(this.activePost.custom_fields.author.ID, id);
    }*/
  }

  getPostByID(id) {
    this.axios.get(this.CMS_URL + 'mt7rk/get_post_by_id&id=' + id).then((response) => {
      //console.log(response.data);
      //this.$store.commit('setPage', JSON.parse(response.data));
      this.activePost = JSON.parse(response.data);
      this.$forceUpdate();
      //console.log(this.activePost);
      setTimeout(() => {
        this.parseDoc();
      }, 10);
    });
  }

  parseDoc() {
      this.parsed = true;
      //console.log(window);

      this.parseImages();

      this.parseGallery();

      this.parseEmbeds();
      
  }

  parseEmbeds() {
    this.parseTwitter();
  }

  parseTwitter() {
    let tweets = document.getElementsByClassName('wp-block-embed is-provider-twitter');

    for(let i = 0; i < tweets.length; i++) {
      let id = (tweets[i] as any).innerText.split('/');
      id = id[id.length-1];
      //console.log(id);
      (window as any).twttr.widgets.createTweet(
        id,
        tweets[i],
        {}
      );
              //console.log((tweets[i] as any).innerText.split('/'));
      /*let html = `<iframe style="border:none;" width="550" height="700" data-tweet-url="https://twitter.com/buitengebieden/status/1590063312611704832" srcdoc="<blockquote class='twitter-tweet'><p lang='en' dir='ltr'>Dogs are the best.. 😊 <a href='https://t.co/LcSxAcWpwm'>pic.twitter.com/LcSxAcWpwm</a></p>&amp;mdash; Buitengebieden (@buitengebieden) <a href='https://twitter.com/buitengebieden/status/1590063312611704832?ref_src=twsrc%5Etfw'>November 8, 2022</a></blockquote>"></iframe>`;
      console.log(html);
      (tweets[i] as any).innerHTML = html;

      if(i == tweets.length-1) {
        let twitterScript = document.createElement('script');
        twitterScript.setAttribute('src', 'https://platform.twitter.com/widgets.js' );
        document.head.appendChild(twitterScript);

        twitterScript.onload = () => {
          console.log("hi");
          (window as any).twttr.widgets.load();
        }
      }*/
    } 
  }

  parseImages() {
    let images = document.getElementsByClassName("wp-block-image");
      let html = "<div class='magnifying-glass box-shadow reversed right'></div>";

      for(let i = 0; i < images.length; i++) {
        //console.log((images[i].children[0] as any));
        images[i].children[0].innerHTML = images[i].children[0].innerHTML + html;
        if(images[i].children[0].children[0]) {
          (images[i].children[0].children[0] as any).dataset.full_image = (images[i].children[0] as any).href;
          this.listeners.push(images[i].children[0].addEventListener('click', this.imageClickHandler));
          
          setTimeout(() => {
            if(images[i].children[0].children[1])
              this.listeners.push(images[i].children[0].children[1].addEventListener('click', this.imageClickHandler));

          }, 10);

        }
      }
  }

  parseGallery() {
    let galleries = document.getElementsByClassName("wp-block-gallery");
    let html = "<div class='arrow arrow-left box-shadow right reversed'><img src='"+require('@/assets/img/arrow.svg')+"'/></div><div class='arrow arrow-right box-shadow reversed'><img src='"+require('@/assets/img/arrow.svg')+"'/></div>"
    for(let i = 0; i < galleries.length; i++) {
      galleries[i].innerHTML = galleries[i].innerHTML + html;

      setTimeout(() => {
        this.listeners.push(galleries[i].children[galleries[i].children.length-1].addEventListener('click', this.sliderHandler));
        this.listeners.push(galleries[i].children[galleries[i].children.length-2].addEventListener('click', this.sliderHandler));

      }, 10);
    }
  }

  sliderHandler(e) {
    let dir = (e.currentTarget.classList.contains('arrow-left') ? 1 : -1);

    let currentMargin = (e.currentTarget.parentElement.children[0].style["margin-left"] ? e.currentTarget.parentElement.children[0].style["margin-left"].slice(0,-2) : 0);
    //console.log(e.currentTarget.parentElement.scrollWidth );
    //console.log(e.currentTarget.parentElement.offsetWidth );


    if(dir == -1 && e.currentTarget.parentElement.scrollWidth > e.currentTarget.parentElement.offsetWidth + 360 ) { //going left
      //get first element of slider from our current target
      e.currentTarget.parentElement.children[0].style["margin-left"] = currentMargin-36+'em';


    } else if(dir == 1 && currentMargin < 0) { //going right
      e.currentTarget.parentElement.children[0].style["margin-left"] = parseInt(currentMargin)+36+'em';

    } 

    //console.log(dir);
  }

  imageClickHandler(e) {
    e.preventDefault();
    //console.log(e.target.parentElement);
    if(e.target.dataset.full_image) {
      this.lightboxSrc = e.target.dataset.full_image;

    } else {
      this.lightboxSrc = e.target.parentElement.href;

    }
    //this.lightboxSrc = e.target.dataset.full_image;
    
    //console.log(this.lightboxSrc);
    this.$forceUpdate();
  }

  handleScroll() {
    //console.log(document.getElementsByClassName('img-wrap'))
    let el = (document.getElementsByClassName('img-wrap')[0] as any);
    if(window.scrollY > el.offsetTop + el.offsetHeight) {
      this.scrolled = true;
    } else if(this.scrolled == true && window.scrollY < el.offsetTop) {
      this.scrolled = false;
    }

    //this.scrollPos = window.scrollY;
  }

  checkForm(e) {
    //console.log('checkForm');
    this.warning = '';
    this.success = '';
    if(this.$route.params.id == '527')
      this.sub = 'contact';
    else
      this.sub = 'submit';

    if(this.name) {
      // eslint-disable-next-line
      let emailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

      if(this.email.length > 0 && this.email.match(emailRegex)) {
          if(this.message) {
            this.axios.get(this.CMS_URL + 'mt7rk/contact_us&name=' + this.name + '&email=' + this.email + '&message='+ this.message + '&sub=' + this.sub).then((response) => {
              let resp = response.data;

              if(resp.success) {
                this.success = 'Your message has been sent <span class="ar">تم إرسال رسالتك</span>';
              } else {
                this.warning = 'Error <span class="ar">خطأ</span>';
              }
            });
        } else {
          this.warning = 'Your message is empty <span class="ar">رسالتك فارغة</span>';
          document.getElementById("message").focus();
        }
      } else {
        this.warning = 'Your email is not right <span class="ar">بريدك الإلكتروني غير صحيح</span>';
        document.getElementById("email").focus();
      }
    } else {
      this.warning = 'Your name is empty <span class="ar">اسمك فارغ</span>';
      document.getElementById("name").focus();
    }
    e.preventDefault();
  }

}
