
import { Options, Vue } from 'vue-class-component';
import Grid from '@/components/grid/grid.vue';
import { COMPARISON_BINARY_OPERATORS } from '@babel/types';

import { event } from 'vue-gtag';
import { Vue3Lottie } from 'vue3-lottie';
import LoadJSON from '../../load2.json';

@Options({
  components: {
    Grid,
    Vue3Lottie
  },
  data() {
      return {
        LoadJSON,
      }
  },
  computed: {
    CMS_URL () {
      return this.$store.getters.getCMSURL;
    },
    posts () {
      return this.$store.getters.getPosts;
    }
  },
  watch: {
    '$route' (to) {
      //console.log(to);
      if(to.name == 'tag') {
        //console.log('relaod');
        this.getPosts();
      }
    }
  }
})
export default class BlogPost extends Vue {

  CMS_URL;
  posts;
  list;
  tag;
  currentTag;
  num;
  totalPost;
  filterStatus;
  total = 20;
  numMore;

  created() {
    this.numMore = this.total;
    this.getPosts();
  }

  mounted() {
    let cookies = (this.$parent as any).$root.cookies,
      gdprCookie = cookies.get("gdprCookie"),
      tag = this.$route.params.name;

    if(gdprCookie == 'true') {

      event(null, { 
        event: null, // Event type [default = 'interaction'] (Optional)
        category: 'Tag',
        action: 'click',
        label: 'Tag View: '+tag,
        value: 5000,
        noninteraction: false, // Optional
      });

    }
  }

  getPosts() {
    this.list = [];
    this.filterStatus = false;
    this.currentTag = null;
    this.num = 0;
    this.totalPost = 0;
    this.tag = this.$route.params.name;

    if(!this.posts) {
      this.axios.get(this.CMS_URL + 'mt7rk/posts').then((response) => {
        //console.log(response.data);
        this.$store.commit('setPosts', JSON.parse(response.data).posts);
        //console.log(this.posts);
        this.organizePosts();
      });
    } else {
      this.organizePosts();
    }
  }

  organizePosts() {
    this.totalPost = this.posts.community.length + this.posts.content.length + this.posts.news.length;// + this.posts.compagnies.length;
    
    this.filterByTag(this.posts.community);
    this.filterByTag(this.posts.content);
    this.filterByTag(this.posts.news);
    //this.filterByTag(this.posts.compagnies);
  }

  filterByTag(posts) {
    let num = 1,
      total = posts.length,
      list = [];

    posts.forEach((item) => {
      
        
      if(item.tags) {
        item.tags.forEach((tag) => {
          if(decodeURIComponent(tag.slug) == this.tag) {
            if(!this.currentTag) {
              this.currentTag = tag.name;
            }
            //console.log(list);
            this.list.push(item);
            //this.$forceUpdate();
            return false;
          }
        });
      }
      //console.log(num,total);
      if(num >= total) {
        this.num += total;
        // force update at the end of the filtering
        if(this.num == this.totalPost) {
          //console.log('done');
          //console.log(this.list);
          this.filterStatus = true;
          try{
            this.$forceUpdate();
          }catch(e){
            console.log("caught error");
          }
          //this.list = list;
          //
        }
      } else {
        num++;
      }
    });
  }

  goToBlog(post) {
    this.$store.commit('setActivePost', post);
    this.$router.push({
      name: 'blogpost',
      params: { id: decodeURIComponent(post.post_name) }
    });
  }

  goBack() {
    if((window as any).history.state.back) {
      this.$router.go(-1);
    } else {
      this.$router.push({
        name: 'blog',
      });
    }
    //
  }

  overAnim(id) {
    (this as any).$refs.loadCom.play();
  }

  outAnim(id) {
    (this as any).$refs.loadCom.stop();
  }

  loadMore(e) {
    this.numMore = this.numMore + this.total;
    this.$forceUpdate();
    e.preventDefault();
  }

}
