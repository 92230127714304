
import { Options, Vue } from 'vue-class-component';

import Grid from '@/components/grid/grid.vue';
import Carousel from "@/components/carousel/carousel.vue";
import Post from "@/components/post/post.vue";

import { event } from 'vue-gtag';
import { Vue3Lottie } from 'vue3-lottie';
import LoadJSON from './load2.json';

@Options({
  components: {
    Carousel,
    Post,
    Grid,
    Vue3Lottie
  },
  data() {
    return {
      LoadJSON,
    }
  },
  computed: {
    CMS_URL () {
      return this.$store.getters.getCMSURL;
    },
    posts () {
      return this.$store.getters.getPosts;
    },
    blog () {
      return this.$store.getters.getBlog;
    }
  }
})
export default class BlogView extends Vue {

  CMS_URL;
  posts;
  blog;
  scrollable = true;
  scrollEvents = true;
  cookies;
  communityHover = -1;
  news = [];
  created() {
    //console.log(this.CMS_URL);

    this.getPosts();
    this.getBlog();

  }

  mounted() {
    //window.addEventListener('scroll', this.freezeScroll, {capture: true});
    //window.addEventListener('wheel', this.handleScroll);
    //this.handleScroll(null);
    let cookies = (this.$parent as any).$root.cookies,
      gdprCookie = cookies.get("gdprCookie");
    //console.log('parent');
    //console.log(gdprCookie);

    if(gdprCookie == 'true') {
      //console.log('tracking event', gdprCookie);
      //console.log(this.$gtm);

      event(null, { 
        event: null, // Event type [default = 'interaction'] (Optional)
        category: 'Blog',
        action: 'click',
        label: 'Blog View',
        value: 5000,
        noninteraction: false, // Optional
      });
      /*this.$gtm.trackEvent({
        event: null, // Event type [default = 'interaction'] (Optional)
        category: 'Blog',
        action: 'click',
        label: 'Blog View',
        value: 5000,
        noninteraction: false, // Optional
      });*/
    }
  }

  beforeUnmount() {
    //window.removeEventListener('scroll', this.freezeScroll);
    //window.removeEventListener('wheel', this.handleScroll);
    //this.showScroll(-1);

  }

  addMoreNews() {
    //console.log('more news',this.news);
    let newLength = (this.posts.news.length > this.news.length+20 ? this.news.length+20 : this.posts.news.length);
    //console.log(newLength)
    this.news = this.posts.news.slice(0, newLength);
    //console.log(this.news);
    //console.log(this.posts.news);

  }

  getPosts() {
    if(!this.posts) {
      this.axios.get(this.CMS_URL + 'mt7rk/posts').then((response) => {
        //console.log(response.data);
        this.$store.commit('setPosts', JSON.parse(response.data).posts);
        //console.log(this.posts);
        this.addMoreNews();
      });
    } else {
      this.addMoreNews();
     }
  }

  getBlog() {
    if(!this.blog) {
      this.axios.get(this.CMS_URL + 'mt7rk/get_blog').then((response) => {
        //console.log(response.data);
        this.$store.commit('setBlog', JSON.parse(response.data));
        //console.log(this.blog);
      })
    }
  }

  goToBlog(post) {
    this.$store.commit('setActivePost', post);
    this.$router.push({
      name: 'blogpost',
      params: { id: decodeURIComponent(post.post_name) }
    });
  }

  goToNews(post) {
    this.$router.push({
      name: 'newspost',
      params: { id: decodeURIComponent(post.post_name) }
    });
  }

  goToTag(tag) {
    //console.log(tag);
    this.$router.push({
      name: 'tag',
      params: { name: tag }
    });
  }

  goToCat(cat) {
    //console.log(tag);
    this.$router.push({
      name: 'cat',
      params: { name: cat }
    });
  }

  freezeScroll() {
    let el = document.getElementById('topWrap');

   if(this.scrollEvents && el) {
      if(window.scrollY >= el.offsetTop-140 &&
        window.scrollY <= (el.offsetTop-100)) {
        //window.scrollTo(el.offsetTop-120, 0);
        this.scrollEvents = false;

        setTimeout(() => {
          this.scrollEvents = true;
        }, 250)
        this.hideScroll();
      }
    }
  }

  handleScroll(e) {
    if(!this.scrollable) {
      let scrollDiv = document.getElementById('newsScroll');
      let dir = (e.wheelDelta < 0 ? 1 : -1)

      if(dir == 1 && ((scrollDiv as any).scrollHeight - scrollDiv.scrollTop - scrollDiv.offsetHeight) == 0) {
        //console.log("end");
        this.showScroll(1);
      } else if(dir == -1 && scrollDiv.scrollTop == 0) {
        this.showScroll(-1);
      }

      scrollDiv.scrollTop -= e.wheelDelta;
    }
  }

  hideScroll() {
    if(this.scrollable == true) {
      this.scrollable = false;
      document.body.classList.add('no-scroll');
    }
  }

  showScroll(dir) {
    if(!this.scrollable) {
      

      let el = document.getElementById('topWrap');

      document.body.classList.remove('no-scroll');
      this.scrollEvents = false;

      setTimeout(() => {
        this.scrollEvents = true;
      }, 250)
      
      if(dir == 1) {
        window.scrollTo(0, el.offsetTop)
      } else {
        window.scrollTo(0, el.offsetTop-150)
      }

      this.scrollable = true;
      
    }
  }

  setCommunityHover(index) {
    //console.log(index);
    this.communityHover = index;
  }

  overAnim(id) {
    if(id == 'loadNews')
      (this as any).$refs.loadNews.play();
    else if(id == 'loadNewsMob')
      (this as any).$refs.loadNewsMob.play();
    else if(id == 'loadCon')
      (this as any).$refs.loadCon.play();
    else if(id == 'loadCom')
      (this as any).$refs.loadCom.play();
  }

  outAnim(id) {
    if(id == 'loadNews')
      (this as any).$refs.loadNews.stop();
    else if(id == 'loadNewsMob')
      (this as any).$refs.loadNewsMob.stop();
    else if(id == 'loadCon')
      (this as any).$refs.loadCon.stop();
    else if(id == 'loadCom')
      (this as any).$refs.loadCom.stop();
  }
}
