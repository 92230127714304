
import { Options, Vue } from 'vue-class-component';

import { event } from 'vue-gtag';

@Options({
    components: {},
    computed: {
        CMS_URL () {
            return this.$store.getters.getCMSURL;
        },
        awards () {
            return this.$store.getters.getAwards;
        },
        categories () {
            return this.$store.getters.getCategories;
        }
    }
})
export default class AwardsView extends Vue {

    CMS_URL;
    awards;
    categories;
    countInterval: any;
    countdown: any = null;
    currentDate: any;    

    created() {
        this.getAwards();
    }

    mounted() {
        let cookies = (this.$parent as any).$root.cookies,
          gdprCookie = cookies.get("gdprCookie");

        if(gdprCookie == 'true') {

          event(null, { 
            event: null, // Event type [default = 'interaction'] (Optional)
            category: 'Showcase',
            action: 'click',
            label: 'Showcase View',
            value: 5000,
            noninteraction: false, // Optional
          });

        }
    }

    getAwards() {
        let exclude;
        if(!this.awards) {
            this.axios.get(this.CMS_URL + 'mt7rk/awards').then((response) => {
                //console.log(JSON.parse(response.data));
                this.$store.commit('setAwards', JSON.parse(response.data).page[0]);
                exclude = JSON.parse(response.data).page[0].custom_fields.submission_project.term_id;
                //console.log(this.awards);
                this.launchCountdown();
                this.getCategories(exclude);
            });
        } else {
            //console.log(this.awards);
            exclude = this.awards.custom_fields.submission_project.term_id;
            this.launchCountdown();
            this.getCategories(exclude);
        }
    }

    getCategories(exclude) {
        if(!this.categories) {
            this.axios.get(this.CMS_URL + 'mt7rk/categories&id=40&ex='+exclude).then((response) => {
                //console.log(JSON.parse(response.data));
                this.$store.commit('setCategories', JSON.parse(response.data).categories);
            });
        }
    }

    // set countdown countInterval
    launchCountdown() {
        //console.log('launch');
        this.countInterval = setInterval(() => {
          let countDownDate = new Date(this.awards.custom_fields.countdown).getTime();
          // Get today's date and time
          let now = new Date().getTime();
          // Find the distance between now and the count down date
          let distance = countDownDate - now;
          
          // If the count down is finished, write some text
          if (distance < 0) {
              clearInterval(this.countInterval);
              this.countdown = false;
              //console.log('countdown');
          } else {
            this.countdown = this.setCountdown(this.awards.custom_fields.countdown);
            //console.log(this.countdown);
          }
        }, 1000);
    }

    setCountdown(time) {
        // Set the date we're counting down to
        let countDownDate = new Date(time).getTime();
        // Update the count down every 1 second
        // Get today's date and time
        let now = new Date().getTime();
        // Find the distance between now and the count down date
        let distance = countDownDate - now;
        // Time calculations for days, hours, minutes and seconds
        let days = Math.floor(distance / (1000 * 60 * 60 * 24)),
          hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
          minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
          seconds = Math.floor((distance % (1000 * 60)) / 1000),
          d,
          dM,
          h,
          hM = "H",
          m = (minutes > 1 ? 'MINUTES' : 'MINUTE'),
          mM = "M",
          s,
          sM = "S";

          d = (days > 1 ? 'DAYS' : 'DAY');
          h = (hours > 1 ? 'HOURS' : 'HOUR');
          s = (seconds > 1 ? 'SECONDS' : 'SECOND');
          dM = "D";


        let display = '<div class="notgrey"><span class="number">' + days + '</span><span class="string">' + d + '</span><span class="stringM">' + dM + '</span></div class="notgrey"> <span class="separator">:</span> <div class="notgrey"><span class="number">' + hours + '</span><span class="string">' + h + '</span><span class="stringM">' + hM + '</span></div class="notgrey"> <span class="separator">:</span> <div class="notgrey"><span class="number">' + minutes + '</span><span class="string">' + m + '</span><span class="stringM">' + mM + '</span></div class="notgrey"> <span class="separator">:</span> <div class="notgrey"><span class="number">' + seconds + '</span><span class="string">' + s + '</span><span class="stringM">' + sM + '</span></div>';

        //console.log(display);
        return display;
    }

    isWinner(categories) {
        return categories.find( post => post.custom_fields.status === 'winner' ) !== undefined;
    }

    toggleResults(id,e) {
        let h = document.getElementById("second"+id).offsetHeight;
            //elmt = document.getElementById("grid-second"+id);

        // reset all height
        if(this.categories[id].toggle) {
            this.categories[id].height = 0;
            this.categories[id].toggle = false;
        } else {
            for(let i = 0; i < this.categories.length;i++) {
                this.categories[i].height = 0;
                this.categories[i].toggle = false;
                //console.log(i,this.categories.length);
                if(i == this.categories.length -1) {

                    if(!this.categories[id].toggle) {
                        this.categories[id].height = h;
                        this.categories[id].toggle = true;
                    }
                }
            }
        }
        e.preventDefault();
    }

}
