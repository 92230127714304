
//import * as Vue from "vue";
import { Vue, Options }  from "vue-class-component";
@Options({
  components: {},
  props: ['post', 'pos', 'section'],
  watch: {
    pos() {
      //console.log("change");

      setTimeout(() => {
        this.$forceUpdate();

      }, 200);

    }
  }
})
export default class Post extends Vue {

  post;
  section;
  featured;
  renderedPosts = null;
  activePos = 0;
  windowSize = 'small';

  /*created() {
    console.log("in post");
    console.log(this.post);
  }*/

  mounted() {
    //console.log(this.post);
    this.$forceUpdate();
  }

  goToBlog(post,section='blog') {
    this.$store.commit('setActivePost', post);
    // detect category post
    //console.log(section);
    // for company and individual post
    if(post.post_type == 'company' || post.post_type == 'individual') {
      section = 'directory';
      this.goTo(post,section);
    } else if(post.category && post.category.length > 0) { // detect news post
      for(let i = 0; i < post.category.length;i++) {
        if(post.category[i].slug == 'news') {
          section = post.category[i].slug;
          this.goTo(post,section);
          return false;
        }

        if(i >= post.category.length-1) {
          this.goTo(post,section);
        }
      }
    } else {
      this.goTo(post,section);
    }
  }

  goTo(post,section) {
    this.$router.push({
      name: section+'post',
      params: { id: (post.post_name ? decodeURIComponent(post.post_name) : post.ID) }
    });
  }

  goToTag(tag,e) {
    this.$router.push({
      name: 'tag',
      params: { name: tag }
    });
    e.preventDefault();
  }

  goToCat(cat,e) {
    this.$router.push({
      name: 'cat',
      params: { name: cat }
    });
    e.preventDefault();
  }

  isVisible(): boolean {
    
    if(this.$refs.el) {
      //console.log(this.$refs.el);
      const rect = (this.$refs.el as any).getBoundingClientRect();
      //console.log(rect);
      return (
          rect.left >= 0 &&
          rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      );
    } else 
      return true;

      
  }
  
}
